.footer-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    background-color: #F9FAFB;
    color: #333;
    text-align: center;
}

.content-wrapper {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
}

.footer-left {
    font-size: 0.9rem;
}

.footer-right {
    display: flex;
    gap: 15px;
}

.footer-right img {
    width: 18px;
    height: 18px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0.8;
    cursor: pointer;
}

.footer-right img:hover {
    transform: scale(1.1);
    opacity: 1;
}

/* Adjust layout for small screens */
@media (max-width: 450px) {
    .footer {
        flex-direction: column;
        align-items: center;
        /* Center both rows */
        gap: 10px;
    }

    .footer-left {
        margin-bottom: 5px;
        text-align: center;
        /* Ensure left content is centered */
    }

    .footer-right {
        flex-direction: row;
        /* Keep icons in a row */
        gap: 10px;
        margin-bottom: 20px;
        /* Slightly smaller gap between icons */
    }
}