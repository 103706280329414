.base-button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #4285F4;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: background-color 0.3s ease;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.base-button:hover {
    background-color: #2563eb;
}

.base-button:disabled {
    cursor: auto;
    opacity: 0.3;
}