.task-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.detail-task-container {
    padding: 20px;
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 12px;
    margin: 20px;
}

.detail-task-container p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    white-space: pre-line;
}

.detail-task-container strong {
    font-weight: 600;
    color: #333;
}

.detail-task-container p:last-child {
    margin-bottom: 0;
    display: -webkit-box;
    /* -webkit-line-clamp: 2; */
    /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Add ellipsis for overflow */
}

.detail-task-container .task-title {
    font-size: 18px;
    font-weight: bold;
    color: #4C4C4C;
    margin-bottom: 15px;
}

.detail-task-container .task-date,
.detail-task-container .task-phone,
.detail-task-container .task-status,
.detail-task-container .task-id {
    color: #666;
}

.detail-task-container .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
}

.detail-task-container .close-button:hover {
    color: #333;
}

.calls-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.detail-calls-container {
    padding: 20px;
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 12px;
    margin: 20px;
}

.detail-calls-container p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
}

.detail-calls-container strong {
    font-weight: 600;
    color: #333;
}

.detail-calls-container p:last-child {
    margin-bottom: 0;
    display: -webkit-box;
    /* -webkit-line-clamp: 2; */
    /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Add ellipsis for overflow */
}

.detail-calls-container .call-item {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
}

.detail-calls-container .call-item .transcript {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 8px;
    margin: 5px 0;
    align-self: flex-start;
}

.detail-calls-container .call-item .transcript p {
    background-color: #f9f9f9;
    /* Light grey background */
    border-radius: 5px;
    padding: 8px;
    margin: 5px 0;
    align-self: flex-start;
    word-wrap: break-word;
}

.detail-calls-container .call-item .transcript p.user-message {
    background-color: #e0e0e0;
    /* Light grey background */
    border-radius: 5px;
    padding: 8px;
    margin: 5px 0;
    align-self: flex-start;
    word-wrap: break-word;
}

.detail-calls-container .call-item .transcript p.assistant-message {
    background-color: #d4f8e8;
    /* Light grey background */
    border-radius: 5px;
    padding: 8px;
    margin: 5px 0;
    align-self: flex-start;
    word-wrap: break-word;
}

.detail-calls-container .call-item .transcript h4 {
    margin: 0;
}