.header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fff;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 20px;
    /* Adjust space between logo and nav */
}

.header a {
    text-decoration: none;
}

.logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    margin-right: 10px;
}

.logo {
    height: 40px;
}

.environment-mark {
    font-size: 1.2rem;
    font-weight: 600;
    color: #3B82F6;
    line-height: 1;
}

.header-nav {
    display: flex;
    gap: 25px;
    /* Space between links */
}

.header-nav a {
    text-decoration: none;
    font-size: 1rem;
    color: #333;
    transition: color 0.3s;
}

.header-nav a:hover {
    color: #3B82F6;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 15px;
    /* Space between new task button and profile */
}

.new-task-button {
    height: 35px;
    /* Adjusted height for smaller button */
}

.profile-container {
    position: relative;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 150px;
    z-index: 1000;
}

.dropdown button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.dropdown button:hover {
    background-color: #e0e0e0;
}

.header-nav-mobile {
    display: none;
}

/* Show mobile navigation links on small screens */
@media (max-width: 700px) {
    .header-nav {
        display: none;
    }

    .header-nav-mobile {
        display: flex;
    }
}


.company-name {
    font-size: 1.5rem;
    font-weight: 600;
}