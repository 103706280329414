.contact-us-container {
    background-color: #F9FAFB;
    padding: 30px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    max-width: 800px;
    margin: 40px auto;
}

.contact-us-container h3 {
    font-size: 16px;
    color: #111827;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.contact-us-container p {
    font-size: 14px;
    color: #6B7280;
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.phone-link {
    font-size: 18px;
    color: #3B82F6;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    transition: color 0.3s ease;
}

.phone-link:hover {
    color: #2563EB;
}

.check-icon {
    width: 20px;
    height: 20px;
    stroke: #3B82F6;
    transition: stroke 0.3s ease;
}

.phone-link:hover .check-icon {
    stroke: #2563EB;
}