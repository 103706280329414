.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.form-container-label {
    display: block;
    font-size: 18px;
    color: #000;
}

.form-container-sublabel {
    display: block;
    font-size: 14px;
    color: #000;
}

.form-container-input {
    width: calc(100% - 20px);
    max-width: 300px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #444;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
}