.email-signup {
    padding: 20px 20px 40px 20px;
    text-align: center;
    width: 100%;
}

.email-signup-container {
    max-width: 600px;
    margin: 0 auto;
}

.email-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
    /* Add space between input and button */
    flex-wrap: wrap;
    /* Allow wrapping */
}

.email-input-container {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 0 14px;
    border: 1px solid #D1D5DB;
    flex: 1;
    max-width: 300px;
}

.icon {
    margin-right: 8px;
}

.email-input {
    border: none;
    outline: none;
    font-size: 16px;
    flex: 1;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    min-height: 48px;
}

.waitlist-button {
    color: white;
    border: none;
    height: 48px;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 0 20px;
    max-width: 175px;
    justify-content: center;
}

.signup-description {
    color: #6B7280;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin: 0px 10px;
}

.signup-description a {
    color: #6B7280;
    text-decoration: underline;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

/* Media Query for small screens */
@media (max-width: 600px) {
    .email-form {
        flex-direction: column;
        /* Stack input and button vertically */
        align-items: center;
        /* Make items take full width */
    }

    .waitlist-button {
        width: 100%;
        margin: 0 10px;
        /* Make button full-width */
    }
}