.main-waitlist-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 40px;
    background-color: rgba(52, 152, 219, 0.3);
    border-radius: 24px;
    margin-top: 40px;
}

.main-waitlist-container-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.main-waitlist-container h2 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: xxx-large;
    color: #34495E;
    max-width: 800px;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
}

.main-waitlist-container h4 {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #34495E;
    max-width: 600px;
    margin-left: 10px;
    margin-right: 10px;
}