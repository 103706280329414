@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Quicksand', 'Inter',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

p {
  white-space: pre-line;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

.content {
  /* margin-top: -70px; */
  /* height: 100vh; */

  /* background: radial-gradient(at 53% 78%, rgba(255, 255, 0, 0.3) 0px, transparent 50%),
    radial-gradient(at 71% 91%, rgba(51, 255, 0, 0.3) 0px, transparent 50%),
    radial-gradient(at 31% 91%, rgba(255, 128, 0, 0.17) 0px, transparent 50%); */
  /* background-size: 200% 200%; */
  /* animation: gradientAnimation 10s linear infinite; */
  position: relative;
  /* overflow: hidden; */
  cursor: default;
  margin-top: 0;
}

/* @keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
} */

.title {
  font-size: 2.7rem;
  color: #2c3e50;
  margin-bottom: 10px;
  text-align: center;
}

.subtitle {
  font-size: 1.5rem;
  color: #6e7878;
  margin: 0;
  font-style: italic;
  font-weight: 400;
  text-align: center;
}