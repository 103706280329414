.faq-section {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.faq-header h2 {
    font-size: 32px;
    color: #111827;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.faq-header p {
    font-size: 18px;
    color: #6B7280;
    margin-bottom: 40px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.faq-list {
    border-top: 1px solid #E5E7EB;
}

.faq-item {
    border-bottom: 1px solid #E5E7EB;
    padding: 15px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: #111827;
    text-align: start;
}

.faq-icon {
    font-size: 24px;
    color: #6B7280;
    transition: transform 0.3s ease;
}

.faq-answer {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s ease;
}

.faq-answer p {
    margin-top: 10px;
    font-size: 16px;
    text-align: start;
    color: #4B5563;
    line-height: 1.5;
    font-family: 'Inter', sans-serif;
}