.notification-stack {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.notification {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    border-radius: 8px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    text-align: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    min-width: 450px;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.notification.show {
    opacity: 0.9;
    transform: translateY(0);
}

.notification.hide {
    opacity: 0;
    transform: translateY(-20px);
}

.notification.error {
    background-color: #f44336;
}

.notification.success {
    background-color: #4CAF50;
}

.notification.warning {
    background-color: #ff9800;
}

.notification.info {
    background-color: #2196F3;
}

.notification button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
}

.notification button:hover {
    color: #ddd;
}

.notification button:focus {
    outline: none;
}

.notification button:active {
    transform: scale(0.95);
}