.how-it-works {
    padding: 40px 20px;
    text-align: center;
    background-color: #fff;
}

.how-it-works-header {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.how-it-works-title {
    color: #3B82F6;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.how-it-works-subtitle {
    color: #111827;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 36px;
}

.how-it-works-description {
    font-size: 18px;
    color: #6B7280;
    margin-bottom: 40px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.how-it-works-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    justify-items: center;
    align-items: stretch;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    grid-auto-rows: 1fr;
}

/* @media (max-width: 1000px) {
    .how-it-works-grid {
        grid-template-columns: 1fr;
    }
} */