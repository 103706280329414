.profile-edit-personal-information-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.profile-edit-personal-information-container h4 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.profile-edit-personal-information-form-input-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.profile-edit-personal-information-form-input-section input {
    outline: none;
    font-size: 14px;
    flex: 1;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    min-height: 48px;
    padding: 0 14px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    background-color: transparent;
}

.profile-edit-personal-information-form-input-section label {
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #555;
}

.profile-edit-personal-information-form-horizontal-group {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
}

.profile-edit-personal-information-form-horizontal-group .state-select-container {
    position: relative;
}

.profile-edit-personal-information-form-horizontal-group .state-select-container select {
    appearance: none;
    flex: 1;
    width: 100%;
    height: 48px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 0 14px;
    background-color: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    color: #000;
    cursor: pointer;
    outline: none;
}

.profile-edit-personal-information-form-horizontal-group .state-select-container::before,
.profile-edit-personal-information-form-horizontal-group .state-select-container::after {
    --size: 0.3rem;
    position: absolute;
    content: "";
    right: 1rem;
    pointer-events: none;
}

.profile-edit-personal-information-form-horizontal-group .state-select-container::before {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-bottom: var(--size) solid black;
    top: 40%;
}

.profile-edit-personal-information-form-horizontal-group .state-select-container::after {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid black;
    top: 55%;
}