.task-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    width: calc(100% - 40px);
    border: 1px solid #c2c2c2;
    border-radius: 8px;
    margin-bottom: 10px;
    gap: 10px;
    background-color: white;
    transition: background-color 0.3s ease, border-width 0.3s ease;
}

.task-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.task-date {
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
}

.task-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.task-description {
    font-size: 14px;
    color: #333;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
}

.task-status-queued,
.task-status-in_progress,
.task-status-completed,
.task-status-research,
.task-status-call_back_same_number,
.task-status-call_back_different_number,
.task-status-in_progress_scheduled_call,
.task-status-more_information_required {
    display: flex;
    font-size: clamp(10px, 2vw, 14px);
    font-weight: bold;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    padding: 5px 15px;
    height: 20px;
    white-space: nowrap;
}

.task-status-queued {
    color: blue;
    border-radius: 10px;
    background-color: #e0f7fa;
}

.task-status-in_progress {
    color: rgb(255, 0, 0);
    border: 2px solid rgb(255, 0, 0);
    background-color: #ffcccc;
}

.task-status-completed {
    color: green;
    border: 2px solid green;
    background-color: #d4edda;
}

.task-status-research {
    color: #474646;
    border: 2px solid #474646;
    background-color: #f0f0f0;
}

.task-status-call_back_same_number {
    color: #474646;
    border: 2px solid #474646;
    background-color: #f0f0f0;
}

.task-status-call_back_different_number {
    color: #474646;
    border: 2px solid #474646;
    background-color: #f0f0f0;
}

.task-status-more_information_required {
    color: #474646;
    border: 2px solid #474646;
    background-color: #f0f0f0;
}

.task-status-in_progress_scheduled_call {
    color: rgb(230, 0, 255);
    border: 2px solid rgb(230, 0, 255);
    background-color: rgb(248, 186, 255);
}

.task-item:last-child {
    margin-bottom: 0;
}