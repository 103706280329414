.circle-spinner {
    border-radius: 50%;
    width: 12px;
    min-height: 12px;
    /* margin: 10px; */
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}