.redeem-invite-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 40px;
    background-color: rgba(52, 152, 219, 0.3);
    border-radius: 24px;
}

.redeem-invite-code-action-container {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;
}

.redeem-invite-code-input {
    outline: none;
    font-size: 16px;
    flex: 1;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    min-height: 48px;
    padding: 0 14px;
    border-radius: 10px;
    border: none;
    background-color: transparent;
}

.redeem-invite-code-input-container {
    background-color: white;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding-right: 10px;
    gap: 10px;
}

.redeem-invite-code-input-container-valid {
    background-color: rgb(202, 253, 223);
    outline: none;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #2ECC71;
    padding-right: 10px;
    gap: 10px;
}

.redeem-invite-code-input-container-invalid {
    background-color: rgb(251, 208, 208);
    outline: none;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 2px solid red;
    padding-right: 10px;
    gap: 10px;
}

.redeem-invite-code-container h4 {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #34495E;
    max-width: 600px;
    margin-left: 10px;
    margin-right: 10px;
}