.marquee-text-section {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    position: relative;
    gap: 20px;
    padding-bottom: 32px;
}

.marquee-content {
    animation: scroll 20s linear infinite;
    position: relative;
    white-space: nowrap;
    will-change: transform;
    display: flex;
    justify-content: flex-start;
}

.marquee-text-pill {
    display: flex;
    background-color: white;
    padding: 8px 22px;
    margin-right: 16px;
    border-radius: 20px;
    border: 1px solid #E5E7EB;
    font-size: 14px;
    white-space: nowrap;
    color: black;
    background-color: #F9FAFB;
}


@keyframes scroll {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-50%);
    }
}