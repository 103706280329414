.blog-post-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
    width: calc(100% - 40px);
    /* height: 100vh; */
    margin: 20px;
}

.blog-title-h1 {
    font-size: 2rem;
    font-weight: 800;
    text-align: center;
    margin: 20px 30px;
}

@media (max-width: 650px) {
    .blog-title-h1 {
        text-align: left;
    }
}

.blog-post-container h2 {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: start;
    margin: 10px 0;
}

.blog-post-container p {
    font-size: 1rem;
    font-weight: 400;
    text-align: start;
    margin: 10px 0;
}

.blog-post-container img {
    width: calc(100% - 20px);
    margin: 10px 0 10px 0;
    max-height: 350px;
    display: block;
    align-self: flex-start;
    max-width: 700px;
}

.blog-post-container ul {
    margin-left: 20px;
    padding-left: 0;
}

.blog-post-container li {
    margin: 5px;
}

@media (max-width: 650px) {
    .blog-post-container ul {
        margin-left: 5px;
    }
}

.author-bio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.author-bio img {
    width: 60px;
    border-radius: 50%;
}

.author-bio-text {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.author-bio-text p {
    margin: 5px;
}