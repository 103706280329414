.task-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 40px);
    overflow-y: auto;
    max-height: calc(100vh - 75px);
    margin: 0px 20px;
}

.tasks-section-container {
    margin-bottom: 20px;
    width: 100%;
}

.fetching-more-tasks {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
