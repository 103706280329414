.brands-container {
    padding: 20px;
    text-align: center;
}

.brands-container h2 {
    font-size: 18px;
    color: #6B7280;
    margin-bottom: 40px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.brands-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 0 50px;
}

.brands-grid-item {
    text-align: center;
}

.brands-grid-item img {
    width: 150px;
    height: 80px;
    object-fit: contain;
}

@media (max-width: 1200px) {
    .brands-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 700px) {
    .brands-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 500px) {
    .brands-grid {
        grid-template-columns: 1fr;
    }
}