.chat-window-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    max-width: 500px;
    gap: 20px;
}

.chat-window {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 500px;
    height: 500px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chat-messages {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 5px;
}

.chat-bubble {
    max-width: 80%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 15px;
    word-wrap: break-word;
}

.typing-indicator {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;
    /* Space between dots */
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #8b8b8b;
    /* Dot color */
    animation: dot-wave 1s infinite;
    margin-top: 3px;
    /* Animation for wave effect */
}

.dot:nth-child(1) {
    animation-delay: 0s;
    /* First dot */
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
    /* Second dot */
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
    /* Third dot */
}

@keyframes dot-wave {

    0%,
    100% {
        transform: translateY(0);
        /* Original position */
    }

    50% {
        transform: translateY(-5px);
        /* Move up */
    }
}

.outgoing {
    align-self: flex-end;
    background-color: #d4f8e8;
    /* Greenish background */
    color: #333;
}

.incoming {
    align-self: flex-start;
    background-color: #e0e0e0;
    /* Grayish background */
    color: #333;
}

.chat-input-container {
    display: flex;
    margin-top: 10px;
    gap: 10px;
}

.chat-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
}

.send-button {
    background-color: #4caf50;
    /* Greenish button color */
    color: white;
    border: none;
    border-radius: 0 15px 15px 0;
    padding: 10px 15px;
    cursor: pointer;
}

.send-button:disabled {
    background-color: #ddd;
    /* Disabled button color */
    cursor: not-allowed;
}

.chat-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}