.landing-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.scrollable-container {
    overflow-y: auto;
    height: calc(100vh - 60px);
    width: 100%;
    padding: 0px;
}