.profile-add-insurance-information-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.profile-add-insurance-information-container h4 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.profile-add-insurance-information-form-input-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.profile-add-insurance-information-form-input-section input {
    outline: none;
    font-size: 14px;
    flex: 1;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    min-height: 48px;
    padding: 0 14px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    background-color: transparent;
}

.profile-add-insurance-information-form-input-section label {
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #555;
}

.profile-add-insurance-information-form-horizontal-group {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
}