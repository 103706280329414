.dashboard-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  overflow: hidden;
  /* Prevent parent scrolling */
  width: 100%;
  height: 100vh;
  /* Ensure it takes full screen height */
}

@media (max-width: 900px) {
  .dashboard-page {
    flex-direction: column;
  }
}

.dashboard-page p {
  font-size: 1.5rem;
  margin-bottom: 3rem;
  text-align: center;
  color: #555;
}

.dashboard-page h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #333;
}

.dashboard-page h1.detail {
  color: #4C4C4C;
  font-weight: bold;
  font-family: Calibri;
  font-size: 20px;
  /* Added 'px' to the font-size */
}

.dashboard-page h1 span.name {
  color: #FF0000;
  font-weight: bold;
  font-family: Tahoma;
  font-size: 20px;
  /* Added 'px' to the font-size */
}