.info-card {
    background-color: #F9FAFB;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    text-align: start;
    max-width: 300px;
    transition: transform 0.2s ease-in-out;
}

.info-card:hover {
    transform: scale(1.05);
}

.card-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.info-card h3 {
    font-size: 18px;
    color: #111827;
    margin-bottom: 8px;
}

.info-card p {
    font-size: 14px;
    color: #6B7280;
}