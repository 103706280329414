.edit-profile-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.edit-profile {
    max-width: 600px;
    margin: auto;
    margin-top: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: Inter, sans-serif;
}

.edit-profile h2 {
    text-align: center;
    margin-bottom: 20px;
}

.profile-medical-info-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.profile-medical-info-container h5 {
    text-align: left;
    margin: 0;
}

.profile-medical-info-container h4 {
    margin: 0;
}

.profile-medical-info-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.profile-medical-info-container-header button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.profile-medical-info-container-header button:hover {
    background-color: #0056b3;
}

.profile-medical-info-container-item-section {
    display: flex;
    flex-direction: row;
    gap: 15px;
    overflow-x: auto;
    overflow-y: hidden;
}

.profile-medical-info-container-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    min-width: 200px;
}

.profile-medical-info-container-item p {
    margin: 0;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.profile-medical-info-container-item-medication {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    min-width: 200px;
    background-color: #F5F5F5;
}

.profile-medical-info-container-item-medication p {
    margin: 0;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.profile-medical-info-container-item-insurance {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    min-width: 200px;
    background-color: #ffd3d3;
}

.profile-medical-info-container-item-insurance p {
    margin: 0;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.profile-medical-info-container-item-doctor {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    min-width: 200px;
    background-color: #b8f0ff;
}

.profile-medical-info-container-item-doctor p {
    margin: 0;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.profile-dropdown-container {
    position: relative;
}

.profile-dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 150px;
    z-index: 1000;
}

.profile-dropdown button {
    background-color: #f0f0f0;
    border: none;
    border-radius: 0px;
    padding: 10px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: #000;
    font-weight: 400;
}

.profile-dropdown button:hover {
    background-color: #e0e0e0;
}

.add-profile-form-overlay-container {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.354);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.add-profile-form-overlay-background-container {
    position: absolute;
    background-color: rgb(255, 255, 255);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 640px;
    height: auto;
    z-index: 1000;
    border-radius: 10px;
}

.add-profile-form-container-entry {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
}

.add-profile-form-container-entry-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-profile-form-container-entry-header h4 {
    margin: 0;
}

.add-profile-form-container-entry-header .close-button {
    background-color: transparent;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    justify-content: center;
    align-items: center;
}

.profile-loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}